<template>
  <div class="Text part" :class="[ payload.optionen ]">
    <div class="constrain">
      <recaptcha :ref="v => recaptchaRef = v?.$el"  />
      <h2 v-html="payload.titel" />
      <form ref="formRef" class="form">
        <input hidden name="form" :value="formName" >
        <input hidden name="first_name" value="somename" >
        <div
          v-if="payload.ivformular && payload.ivformular.length"
          class="form-part"
        >
          <div class="title" v-text="payload.texts.customer" />

          <div class="customer inputs">
            <div class="input">
              <label for="ivLocation" v-text="`${payload.texts.ivLocation} *`" />
              <input
                id="ivLocation"
                v-model="formData.ivLocation"
                type="text"
                required
                name="iv_location"
              >
            </div>
            <div class="input">
              <label for="specialist" v-text="`${payload.texts.specialist} *`" />
              <input
                id="specialist"
                v-model="formData.ivSpecialist"
                type="text"
                required
                name="specialist"
              >
            </div>
            <div class="input">
              <label for="function" v-text="`${payload.texts.function} *`" />
              <input
                id="function"
                v-model="formData.ivFunction"
                type="text"
                required
                name="function"
              >
            </div>
            <div class="input">
              <label for="mail" v-text="`${payload.texts.mail} *`" />
              <input
                id="mail"
                v-model="formData.email"
                type="email"
                required
                name="mail"
              >
            </div>
            <div class="input">
              <label for="phone" v-text="`${payload.texts.phone} *`" />
              <input
                id="phone"
                v-model="formData.ivPhone"
                type="text"
                required
                name="phone"
              >
            </div>
          </div>
        </div>
        <div
          v-else
          class="form-part"
        >
          <div class="title" v-text="payload.texts.employer" />
          <div class="employer inputs">
            <div class="input">
              <label for="companyName" v-text="`${payload.texts.company} *`" />
              <input
                id="companyName"
                v-model="formData.customer_company_name"
                type="text"
                required
                name="customer_company_name"
              >
            </div>
            <div class="input">
              <label for="customerContact" v-text="`${payload.texts.contact_person} *`" />
              <input
                id="customerContact"
                v-model="formData.customerContact"
                type="text"
                required
                name="customer_contact"
              >
            </div>
            <div class="input">
              <label for="customerAddress" v-text="`${payload.texts.address} *`" />
              <input
                id="customerAddress"
                v-model="formData.customerAddress"
                type="text"
                required
                name="customer_address"
              >
            </div>
            <div class="input">
              <label for="customerPlz" v-text="`${payload.texts.plz} *`" />
              <input
                id="customerPlz"
                v-model="formData.customerPlz"
                type="text"
                required
                name="customer_plz"
              >
            </div>
            <div class="input">
              <label for="customerPhone" v-text="`${payload.texts.phone} *`" />
              <input
                id="customerPhone"
                v-model="formData.customerPhone"
                type="text"
                required
                name="customer_phone"
              >
            </div>
            <div class="input">
              <label for="customerMail" v-text="`${payload.texts.mail} *`" />
              <input
                id="customerMail"
                v-model="formData.email"
                type="email"
                required
                name="customer_mail"
              >
            </div>
            <div class="input">
              <label for="customerInsurance" v-text="`${payload.texts.insurance} *`" />

              <select
                id="customerInsurance"
                v-model="formData.customerInsurance"
                type="text"
                required
                name="customer_insurance"
              >
                <option value="" disabled>{{payload.texts.choose}}</option>
                <option>KTG</option>
                <option>UVG</option>
                <option>BVG</option>
                <option>KK</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-part">
          <div class="title" v-text="payload.texts.product" />

          <div class="product inputs">
            <div
              v-for="product in payload.produkte"
              :key="product.id"
              class="input radio"
            >
              <input
                :id="`product-${product.id}`"
                v-model="formData.product"
                name="product"
                type="radio"
                :value="product.name"
              >
              <label :for="`product-${product.id}`" v-text="product.name" />
            </div>
          </div>
        </div>
        <div
          class="form-part"
        >
          <div class="title" v-text="payload.texts.coordinationClient" />

          <div class="client inputs">
            <div class="input">
              <label for="name" v-text="`${payload.texts.name} *`" />
              <input
                id="name"
                v-model="formData.clientName"
                type="text"
                required
                name="client_name"
              >
            </div>
            <div class="input">
              <label for="birthdate" v-text="`${payload.texts.birthdate} *`" />
              <input
                id="birthdate"
                v-model="formData.clientBirthdate"
                type="date"
                required
                name="client_birthdate"
              >
            </div>
            <div class="input">
              <label for="address" v-text="`${payload.texts.address} *`" />
              <input
                id="address"
                v-model="formData.clientAddress"
                type="text"
                required
                name="client_address"
              >
            </div>
            <div class="input">
              <label for="plz" v-text="`${payload.texts.plz} *`" />
              <input
                id="plz"
                v-model="formData.clientPlz"
                type="text"
                required
                name="client_plz"
              >
            </div>
            <div class="input">
              <label for="mail" v-text="`${payload.texts.mail} *`" />
              <input
                id="mail"
                v-model="formData.clientMail"
                type="email"
                required
                name="client_mail"
              >
            </div>
            <div class="input">
              <label for="phone" v-text="`${payload.texts.phone} *`" />
              <input
                id="phone"
                v-model="formData.clientPhone"
                type="text"
                required
                name="client_phone"
              >
            </div>
            <div class="input">
              <label for="insuranceNumber" v-text="`${payload.texts.insuranceNumber} *`" />
              <input
                id="insuranceNumber"
                v-model="formData.client_insurance_number"
                type="text"
                required
                name="client_insurance_number"
              >
            </div>
          </div>
        </div>
        <div
          v-if="payload.ivformular && !payload.ivformular.length"
          class="form-part"
        >
          <div class="title" v-text="payload.texts.coordinatesEmployer" />
            <div class="employer inputs">
              <div class="input">
                <label for="employerCompanyName" v-text="`${payload.texts.company} `" />
                <input
                  id="employerCompanyName"
                  v-model="formData.employer_company_name"
                  type="text"
                  name="employer_company_name"
                >
              </div>
              <div class="input">
                <label for="employerContact" v-text="`${payload.texts.contact_person} `" />
                <input
                  id="employerContact"
                  v-model="formData.employerContact"
                  type="text"
                  name="employer_contact"
                >
              </div>
              <div class="input">
                <label for="employerAddress" v-text="`${payload.texts.address} `" />
                <input
                  id="employerAddress"
                  v-model="formData.employerAddress"
                  type="text"
                  name="employer_address"
                >
              </div>
              <div class="input">
                <label for="employerPlz" v-text="`${payload.texts.plz} `" />
                <input
                  id="employerPlz"
                  v-model="formData.employerPlz"
                  type="text"
                  name="employer_plz"
                >
              </div>
              <div class="input">
                <label for="employerPhone" v-text="`${payload.texts.phone} `" />
                <input
                  id="employerPhone"
                  v-model="formData.employerPhone"
                  type="text"
                  name="employer_phone"
                >
              </div>
              <div class="input">
                <label for="employerMail" v-text="`${payload.texts.mail} `" />
                <input
                  id="employerMail"
                  v-model="formData.employerMail"
                  type="text"
                  name="employer_mail"
                >
              </div>
            </div>
        </div>
        <div class="form-part">
          <div class="title" v-text="payload.texts.assignment" />

          <div class="assignment inputs">
            <div class="input">
              <label for="goal" v-text="payload.texts.goal" />
              <textarea
                id="goal"
                v-model="formData.goal"
                rows="4"
                name="goal"
              />
            </div>
            <div class="input checkbox">
              <input
                id="callback"
                v-model="formData.callback"
                name="callback"
                type="checkbox"
              >
              <label for="callback" v-text="payload.texts.callRequest" />
            </div>
          </div>
        </div>
        <div class="form-part">
          <div class="title" v-text="payload.texts.costApproval" />
          <span class="fo3" v-text="payload.texts.tarifs" />

          <div class="cost inputs">
            <div class="input">
              <label for="hours" v-text="payload.texts.hours" />
              <input
                id="hours"
                v-model="formData.hours"
                type="text"
                name="hours"
              >
            </div>
            <div class="input">
              <label for="costCeiling" v-text="payload.texts.costCeiling" />
              <input
                id="costCeiling"
                v-model="formData.costCeiling"
                type="text"
                name="cost_ceiling"
              >
            </div>
            <div class="input">
              <label for="orderDate" v-text="`${payload.texts.orderDate} *`" />
              <input
                id="orderDate"
                v-model="formData.orderDate"
                type="date"
                required
                name="order_date"
              >
            </div>
          </div>
        </div>
        <div class="row-12 mt-1">
          <div class="col-12 md:col-8">
            <label for="files">Falls Sie uns Dateien senden möchten können Sie diese hier hochladen</label>
            <input
              id="files"
              ref="file"
              name="file[]"
              type="file"
              class="w-auto"
              multiple
              @change="fileChange($event)"
            >
          </div>
        </div>
        <div class="form-part">
          <div class="input checkbox">
            <input
              id="dataProtection"
              v-model="formData.dataProtection"
              name="data_protection"
              required
              type="checkbox"
            >
            <label for="dataProtection" v-html="payload.texts.dataProtectionStatement" />
          </div>
        </div>
        <span class="fo2" v-text="payload.texts.remark" />
        <button @click.prevent="submit" v-text="payload.texts.send" />
      </form>
    </div>
  </div>
</template>

<script>
import useForm from '@/hooks/form';

export default {
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    let formName = 'fallmeldeformular';

    const data = {
      product: null,
      first_name: 'ignore_this_field',
    };

    if (props.payload.ivformular && props.payload.ivformular.length) {
      formName = 'fallmeldeformular-iv';
    } else {
      data.customer_insurance = '';
    }

    const {
      recaptchaRef,
      formRef,
      formData,
      submit,
    } = useForm({
      formName,
      data,
      captchaKey: '6Le7Ea4gAAAAAPbYWkkx6oppD-s13-sDHQrfCE-M',
      successMsg: 'Das Formular wurde erfolgreich gesendet',
      errorMsg: 'Beim Senden des Formulares ist ein Fehler aufgetreten.',
    });

    const fileChange = (event) => {
      formData.file = event.target.files;
    };

    return {
      recaptchaRef,
      formRef,
      formData,
      formName,
      submit,
      fileChange,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_type.scss";

h2 {
  color: var(--color-area);
  margin-bottom: 1.5rem;
}

.inputs.customer {
  display: grid;
  grid-row-gap: 1rem;
  grid-column-gap: 1.5rem;

  @include responsive('grid-template-columns', 1fr, 1fr 1fr);

  .input:first-child {
    @include responsive('grid-column', unset, span 2);
  }
}

.product.inputs {
  .input {
    line-height: 0;
  }
}

.inputs.employer {
  display: grid;
  grid-row-gap: 1rem;
  grid-column-gap: 1.5rem;

  @include responsive('grid-template-columns', 1fr, 1fr 1fr);
}

.inputs.client {
  display: grid;
  grid-row-gap: 1rem;
  grid-column-gap: 1.5rem;

  @include responsive('grid-template-columns', 1fr, 1fr 1fr);
}

.inputs.cost {
  display: grid;
  grid-row-gap: 1rem;
  grid-column-gap: 1.5rem;

  @include responsive('grid-template-columns', 1fr, 1fr 1fr);

  .input:last-child {
    @include responsive('grid-column', unset, span 2);
  }
}

form {
  display: flex;
  flex-flow: column;
  row-gap: 3rem;
}

input[type=file] {
  width: auto!important;
  border-width: 2px;
  color: #aaa;
}

label {
  display: inline-block;
  font-size: 1rem;
  color: #000;
  margin-bottom: 0.3em;
}

input {
  border: 1px solid var(--color-blue);
  width: 100%;
  padding: 0.3em;
  font-size: 1rem;
  color: #000;
}

</style>
