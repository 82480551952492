import {
  ref, reactive, inject, onMounted,
} from 'vue';
import { useStore } from 'vuex';

const defaultOpts = {
  formName: 'form',
  captchaKey: null,
  data: {},
  successMsg: 'Das Formular wurde erfolgreich gesendet.',
  errorMsg: 'Beim Senden des Formulares ist ein Fehler aufgetreten.',
  captchaErrorMsg: 'Fehler beim Validieren des Captcha',
};

/* eslint-disable */
const camelCaseToSnakeCase = (match, p1, p2, p3) => {
  return `${p1}_${p2.toLowerCase()}`;
}

function snakeCase(key) {
  return key.replace(/(\b[a-z]+|\G(?!^))((?:[A-Z]|\d+)[a-z]*)/g, camelCaseToSnakeCase
  );
}
/* eslint-enable */

export default function useForm({
  data, errorMsg, successMsg, formName, captchaErrorMsg, captchaKey,
} = defaultOpts) {
  const recaptchaRef = ref(null);
  const formRef = ref(null);

  const formData = reactive(data || {});

  const store = useStore();

  const toast = inject('$toast');

  const sendForm = async (token) => {
    try {
      const form = new FormData();

      Object.entries(formData).forEach(([key, value]) => {
        if (value instanceof FileList) {
          for (let fileIdx = 0; fileIdx < value.length; fileIdx++) {
            const f = value[fileIdx];
            form.append(`${key}[]`, f);
          }
        } else {
          form.set(snakeCase(key), value);
        }
      });

      form.set('form', formName);
      form.set('g-recaptcha-response', token);
      const result = await fetch(
        `${store.state.router.base}/${store.state.router.locale}/ajax/forms/new_request/notify`,
        {
          method: 'POST',
          body: form,
        },
      );
      const response = await result.json();
      if (response.type === 'error') {
        console.error(response.message);
        toast.error(errorMsg);
      } else {
        toast.success(successMsg);
        Object.assign(formData, {});
      }
    } catch (e) {
      console.error(e);
      toast.error(errorMsg);
    }
  };

  onMounted(() => {
    if (captchaKey) {
      window.grecaptcha.render(recaptchaRef.value, {
        sitekey: captchaKey,
        callback: sendForm,
        errorCallback: () => toast.error(captchaErrorMsg),
      }, true);
    }
  });

  const submit = async () => {
    if (formRef.value.reportValidity()) {
      window.grecaptcha.execute();
    }
  };

  return {
    recaptchaRef,
    formRef,
    formData,
    submit,
  };
}
